import React from 'react';
import ImageQuilt from '../components/imageQuilt/ImageQuilt'


function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }
const images = require.context('../assets/gallery', true);
const imageList = images.keys().map(image => images(image)).map(image => {
    return {img: image, title: image, rows: getRandomInt(3,6), cols: getRandomInt(3,6)}
});
const containerStyle = {
    height: '10vh', // Full height of the viewport
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    textAlign: 'center'
};

const headerStyle = {
    color: '#4A4A4A', // Choose a color that fits your design
    textAlign: 'center', // Center-align the text
    marginBottom: '20px', // Add some space below the header
    fontFamily: 'Arial, sans-serif', // Use a standard, web-safe font
    fontSize: '2rem', // Make the font size larger
    fontWeight: 'bold', // Make the header bold
    textTransform: 'uppercase', // Capitalize all letters
    letterSpacing: '1px', // Add some spacing between letters for a more elegant look
    lineHeight: '1.2', // Adjust line height for better readability
    borderBottom: '2px solid #0056b3', // Add a bottom border for emphasis
    paddingBottom: '10px', // Space between text and border
    display: 'inline-block', // Required for the border to fit the text width
    margin: '20px 0' // Margin to add space around the element
};

const GalleryPage = () => {
    console.log('++++++++++++++++images listing ++++++++++++++++++',imageList)
    return (
        <div>
            <div style={containerStyle}>
            <h2 style={headerStyle}>Gallery</h2>
        </div>
            <ImageQuilt list = {imageList} />
            {/* <div>
            {imageList.map((image, index) => (
                <img key={index} src={image} style={{margin:10, width:800, height:500, borderRadius:40, shadowOffset: { width: 0, height:0 },
                shadowOpacity: 0.5,
                shadowRadius: 10}} alt={`image-${index}`} />
            ))}
            </div> */}
        </div>
    );
};

export default GalleryPage;
