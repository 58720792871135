import React from 'react';

const NoticePage = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: 'blue', margin: '20px 0', textAlign: 'center', maxWidth: '800px', width: '100%', padding: '10px' }}>सूचनाएं</h2>

            <div className="notices-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="card" style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px', margin: '10px', maxWidth: '1000px', width: '100%' }}>
                    <h3>प्रवेश खुला है</h3>
                    <p>हमें खुशी है कि हम अब प्रवेश खोल रहे हैं। अधिक जानकारी के लिए वेबसाइट पर जाएं।</p>
                </div>

                <div className="card" style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px', margin: '10px', maxWidth: '1000px', width: '100%' }}>
                    <h3>ट्रांसफर सर्टिफिकेट देख सकते हैं</h3>
                    <p>अब आप अपना ट्रांसफर सर्टिफिकेट वेबसाइट पर देख सकते हैं। कृपया लॉगिन करें और अपना सर्टिफिकेट डाउनलोड करें।</p>
                </div>

                <div className="card" style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px', margin: '10px', maxWidth: '1000px', width: '100%' }}>
                    <h3>रिजल्ट देखने की सुविधा जल्द ही लॉन्च होगी</h3>
                    <p>हम खुश हैं कि हम जल्द ही रिजल्ट देखने की सुविधा लॉन्च करेंगे। इसके बारे में अधिक जानकारी के लिए हमारे वेबसाइट पर जाएं।</p>
                </div>
            </div>
        </div>
    );
};

export default NoticePage;
