import React from 'react';
import { Box, Typography } from '@mui/material';
import './HeroSection.css'; // Ensure this path is correct

const HeroSection = () => {
    return (
        <Box className="heroContainer" >
            {/* Card for text content */}
            {/* <Box className="heroCard"> */}
                {/* <Typography  className="heroText" style={ {fontSize: "2.5rem", fontFamily: "sans-serif"}}>
            कन्या शिक्षा परिसर  (KSP)
                </Typography>
                <Typography className="tagline">
                बीजाडांडी MANDLA M.P <br/> 
                    संस्‍कारों के साथ सर्वांगीण विकास
                </Typography> */}
                {/* Uncomment the button if needed */}
                {/* <Button variant="contained" color="primary" className="ctaButton">
                    Get Started
                </Button> */}
            {/* </Box> */}
        </Box>
    );
};

export default HeroSection;
