import React from 'react';
import aboutImage from '../assets/logo/about.png'
import whyImage from '../assets/logo/why.png'
import determineImage from '../assets/logo/determine.png'
import admissionImage from '../assets/logo/admission.png'

const HomePage = () => {
    return (
        <section className="about_section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="about_img-container">
                            <img src={aboutImage} alt="About" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about_detail-box">
                            <h3>About our School</h3>
                            <p>कन्या शाला परिसर का उद्देश्य सिद्ध जाति (एसटी) के छात्रों को दूरस्थ क्षेत्रों में मध्य और उच्च स्तरीय गुणवत्ता वाली शिक्षा प्रदान करना है, न केवल उन्हें उच्च और पेशेवर शैक्षणिक पाठ्यक्रमों में आरक्षण के लाभ उठाने की सुविधा प्रदान करके, बल्कि सरकारी और सार्वजनिक और निजी क्षेत्रों में नौकरियों में भी, बल्कि गैर एसटी आबादी के साथी शिक्षा के सर्वोत्तम अवसरों का पहुंच प्राप्त करने की भी। इसे निम्नलिखित द्वारा प्राप्त किया जाएगा:</p>
                  <ul>
                    <li>हर केएसपी बीजाडांडी  में नामांकित सभी छात्रों के समग्र शारीरिक, मानसिक और सामाजिक उपयुक्त विकास का समर्थन करना। छात्र बदलाव का अधिकारी बनाए जाएंगे, अपने स्कूल, अपने घरों, अपने गांवों में और अंत में एक बड़े संदर्भ में।</li>
                    <li>छठे से दसवीं कक्षा तक वालों के लिए उपलब्ध कराई जाने वाली शिक्षाविद्यालयीन सहायता पर विशेष ध्यान केंद्रित करना, ताकि उनकी

                  विशेष आवश्यकताओं को पूरा किया जा सके।</li>
                    <li>छात्र जीवन की शिक्षा, शारीरिक, पर्यावरणीय और सांस्कृतिक आवश्यकताओं को पूरा करने वाले भूमिका के अनुरूप बनाए जाने वाले अवसंरचना का समर्थन करना।</li>
                  </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomePage;
