import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import NavigationBar from './components/NavigationBar';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import AdmissionPage from './pages/AdmissionPage';
import GalleryPage from './pages/GalleryPage';
import NoticePage from './pages/NoticePage';
import ResultPage from './pages/ResultPage';
import ImageSearchPage from './pages/ImageSearchPage';
import FileViewerPage from './pages/FileViewerPage';
import StudentResultPage from './pages/StudentResultPage';
import TickerNotificationBar from './components/TickerNotificationBar';
import YoutubePlayerPage from './pages/YoutubePlayerPage';
import WebsiteLinksPage from './pages/WebsiteLinksPage';





function App() {
  return (
    <>
    <TickerNotificationBar />
    
    <Router>   
    <NavigationBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/AdmissionPage" element={<AdmissionPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/Announcement" element={<NoticePage />} />
        <Route path="/results" element={<ResultPage />} />
        <Route path="/student-results" element={<StudentResultPage />} />
        <Route path="/tc" element={<ImageSearchPage />} />
        <Route path="/public-document" element={<FileViewerPage />} />   
        <Route path="/videos" element={<YoutubePlayerPage />} /> 
        <Route path="/WebsiteLinks" element={<WebsiteLinksPage />} />     
      </Routes>
    </Router>
    <Footer />
    </>
  );
}

export default App;
