import React from 'react';
import { Box, Typography } from '@mui/material';
import './TickerNotificationBar.css'; // Import the CSS file for styling

const messages = [
  "कन्या शिक्षा परिसर स्कूल बीजाडांडी, मध्य प्रदेश में आपका स्वागत है। प्रवेश प्रक्रिया के लिए कृपया नीचे दिए गए लिंक का उपयोग करें: https://www.tribal.mp.gov.in/mptaasschool/SchoolStudent/PreExamRegistration/MPSARASRegistration"
];

const TickerNotificationBar = () => {
  return (
    <Box className="ticker-container">
      <div className="ticker">
        {messages.map((message, index) => (
          <Typography key={index} variant="body1" component="div" className="ticker-item">
            {message}
          </Typography>
        ))}
      </div>
    </Box>
  );
};

export default TickerNotificationBar;
