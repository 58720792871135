import React, { useState, useEffect } from 'react';
import './FileViewerPage.css';

const FileViewerPage = () => {
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState('');

    useEffect(() => {
        const fileNames = ['SCHOOL_UP_AFFILIATION_OASIS.jpeg', 'WATER_TESTING_REPORT.jpeg', 'FIRE_CERTIFICATE.jpeg', 'UNDARTAKING.jpeg','MANDATORY_PUBLIC_DISCLOSURE.jpeg','MEDICAL_CERTIFICATE.jpeg'];
        const filePaths = fileNames.map(name => `/public_documents/${name}`);
        setFiles(filePaths);
    }, []);

    const handleFileClick = (fileName) => {
        setSelectedFile(fileName);
    };

    return (
        <div className="file-viewer-page">
            <div className="file-list">
                <table>
                    <tbody>
                        {files.map(file => {
                            const fileNameWithoutExtension = file.split('/').pop().split('.')[0];
                            const formattedFileName = fileNameWithoutExtension.replace(/_/g, ' '); 
                            return (
                                <tr key={file} onClick={() => handleFileClick(file)}>
                                    <td>{formattedFileName}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="file-view-pane">
                <h3> {selectedFile.split('/').pop().split('.')[0].replace(/_/g, ' ')}</h3>
                {selectedFile && (selectedFile.endsWith('.png') || selectedFile.endsWith('.jpg') || selectedFile.endsWith('.jpeg')) ? (
                    <img className="view-image" src={selectedFile} alt="Selected" />
                ) : (
                    <p>File content cannot be displayed</p>
                )}
            </div>
        </div>
    );
};

export default FileViewerPage;
