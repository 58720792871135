import React from 'react';

const WebsiteLinksPage = () => {
    const links = [
        { name: "Teacher Info", url: "https://docs.google.com/spreadsheets/d/1afXCzvBGsT3IFG7i1Al85IDq2QH2KiUF/edit?usp=sharing&ouid=105106464975007244006&rtpof=true&sd=true" },
        { name: "SC-ST Welfare Department", url: "https://mp.gov.in/sc-st-welfare-department" },
        { name: "Tribal Department", url: "https://www.tribal.mp.gov.in/" },
        { name: "TADP", url: "https://www.tadp.mp.gov.in/" },
        { name: "VANMITRA", url: "https://mpvanmitra.mkcl.org/" },
        { name: "AADIGRAM", url: "https://grants.tribal.gov.in/" },
        { name: "MITRA", url: "https://mymitr.in/" },
        { name: "Learning Management System (LMS)", url: "https://mptriballms.in/" },
        { name: "Inspection Management System (IMS)", url: "http://tribalims.com/" },
        { name: "NIC Scholarship Portal", url: "https://scholarshipportal.mp.nic.in/" },
        { name: "eHRMS", url: "https://hrms.mp.gov.in/" },
        { name: "VANYA Web Portal", url: "http://aadirang.com/" },
    ];

    return (
        <section className="useful_links_section" style={{ padding: '50px 0' }}>
            <div className="container">
                <h2 className="text-center">Useful Links</h2>
                <ul className="list-unstyled">
                    {links.map(link => (
                        <li key={link.name} className="mb-2">
                            <a href={link.url} target="_blank" rel="noopener noreferrer">
                                {link.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default WebsiteLinksPage;
