import React, { useState } from 'react';
import YouTube from 'react-youtube';
import './YoutubePlayerPage.css';

const YoutubePlayerPage = () => {
    const [selectedVideoId, setSelectedVideoId] = useState('INITIAL_VIDEO_ID'); // Replace with initial video ID

    const videoList = [
        'lpc-jGZkbAk', // Example video IDs
        'J1vMUFASC44',
        // ... more video IDs
    ];

    const opts = {
        height: '800',
        width: '1200',
        playerVars: {
            autoplay: 1,
        },
    };

    return (
        <div className="video-player-page">
            <div className="video-list">
                {videoList.map(videoId => (
                    <div key={videoId} className="video-item" onClick={() => setSelectedVideoId(videoId)}>
                        <img src={`https://img.youtube.com/vi/${videoId}/0.jpg`} alt="Thumbnail" className="thumbnail" />
                    </div>
                ))}
            </div>
            <div className="video-pane">
                <YouTube videoId={selectedVideoId} opts={opts} />
            </div>
        </div>
    );
};

export default YoutubePlayerPage;
