import React from 'react';
import './Footer.css'; // Ensure this CSS file contains the necessary styles
import fbImage from '../assets/logo/fb.png'
import igImage from '../assets/logo/instagram.png'
import twImage from '../assets/logo/twitter.png'
import ldImage from '../assets/logo/linkedin.png'
const Footer = () => {
    return (
        <>
            <section className="info_section layout_padding-top" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="info_logo-box">
                    <h2>
                    कन्या शिक्षा परिसर बीजाडांडी
                    </h2>
                </div>
                <div className="container layout_padding2">
                    <div className="row">
                        <div className="col-md-3">
                            <h5>हमारे बारे में</h5>
                            <p>
                            कन्या शिक्षा परिसर बीजाडांडी का उद्देश्य अनुसूचित जनजाति (एसटी) के छात्राओं को दूरस्थ क्षेत्रों में मध्य और उच्च स्तरीय गुणवत्ता वाली शिक्षा प्रदान करके समग्र विकास का समर्थन करना।
               </p>
                        </div>
                        <div className="col-md-3">
                            <h5>उपयोगी लिंक</h5>
                            <ul>
                                <li><a href="result.php">परिणाम</a></li>
                                <li><a href="admission.php">प्रवेश</a></li>
                                <li><a href="">प्रमाणपत्र</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h5>संपर्क करें</h5>
                            <p>कन्या शाला परिसर बैरिटोला कलां, मंडला, मध्य प्रदेश 486882</p>
                        </div>
                        <div className="col-md-3">
                            <div className="subscribe_container">
                                <div className="form_container">
                                    <form action="">
                                        <input type="email" placeholder="Enter your email" />
                                        <button type="submit">जमा करें</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="social_container">
                        <div className="social-box">
                            {/* Replace # with your social media links */}
                            <a href="#"><img src={fbImage} alt="Facebook" /></a>
                            <a href="#"><img src={twImage} alt="Twitter" /></a>
                            <a href="#"><img src={ldImage} alt="LinkedIn" /></a>
                            <a href="#"><img src={igImage} alt="Instagram" /></a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-fluid footer_section">
                <p>
                    Copyright &copy; 2024 All Rights Reserved By
                    <a href="https://html.design/"> कन्या शिक्षा परिसर बीजाडांडी </a>
                </p>
            </section>
        </>
    );
};

export default Footer;
