import React from 'react';
import aboutImage from '../assets/logo/about.png'
import whyImage from '../assets/logo/why.png'
import determineImage from '../assets/logo/determine.png'
import admissionImage from '../assets/logo/admission.png'

const AdmissionPage = () => {
    return (
        <section className="admission_section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="admission_detail-box">
                            <h3>प्रवेश</h3>
                            <p> हमें खुशी है यह सूचित करने में कि हमारे संस्थान में प्रवेश खुल चुके हैं। आपके बच्चे को एक प्रशिक्षित, सुरक्षित और उच्चतम गुणवत्ता वाली शिक्षा का अवसर प्रदान करें। हमारे साथ जुड़ें और उन्नति की ओर एक कदम बढ़ाएं। आवेदन करें और अपने बच्चे के भविष्य को सुरक्षित करें।</p>
                            <a href="https://www.tribal.mp.gov.in/mptaasschool/SchoolStudent/PreExamRegistration/MPSARASRegistration" className="call_to-btn btn_white-border" target="_blank" rel="noopener noreferrer">
                                visit us
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="admission_img-container">
                            <img src={admissionImage} alt="Admission" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AdmissionPage;
