import React from 'react';
import determineImage from '../assets/logo/determine.png';

const ContactPage = () => {
    return (
        <section className="contact_section" style={{ padding: '50px 0' }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="card" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                            <div className="card-body">
                                <h3 className="card-title text-center">संपर्क करें</h3>
                                <p className="text-center">हमारे प्राचार्य से संपर्क करें:</p>
                                <div className="contact_details">
                                    <p><strong>नाम:</strong> डॉ.कैलाश चंद्र गुप्ता</p>
                                    <p><strong>फोन:</strong> 9424743579</p>
                                    <p><strong>पद:</strong> प्राचार्य</p>
                                    <p><strong>जन्म तिथि:</strong> 24-8-1968</p>
                                    <p><strong>नियुक्ति तिथि:</strong> 3-7-2018</p>
                                    <p><strong>कार्यभार ग्रहण तिथि:</strong> 7-11-1991</p>
                                    <p><strong>योग्यता:</strong> Ph.D. (रसायन)</p>
                                    <p><strong>ईमेल:</strong> <a href="mailto:guptakc24@gmail.com">guptakc24@gmail.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactPage;
