import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, useMediaQuery, useTheme, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './NavigationBar.css';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import tribalIcon from '../assets/logo/mptribal-no-bg.png';

const NavigationBar = () => {
    const [anchorElAbout, setAnchorElAbout] = useState(null);
    const [anchorElDocuments, setAnchorElDocuments] = useState(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleAboutClick = (event) => {
        setAnchorElAbout(event.currentTarget);
    };

    const handleDocumentsClick = (event) => {
        setAnchorElDocuments(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElAbout(null);
        setAnchorElDocuments(null);
    };

    return (
        <AppBar position="static" className='top-bar'>
            <Toolbar style={{ minHeight: '80px' }}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                        <IconButton>
                            {tribalIcon ? 
                                <img src={tribalIcon} alt='School Icon' style={{ width: '36px', height: '36px' }} /> : 
                                <HomeIcon />
                            }
                        </IconButton>
                        कन्या शिक्षा परिसर बीजाडांडी
                    </Link>
                </Typography>

                <Button color="inherit" onClick={() => navigate('/')}>Home</Button>
                <Button color="inherit" onClick={() => navigate('/Contact')}>Contact</Button>
                
                <Button color="inherit" onClick={handleAboutClick}>About</Button>
                <Menu
                    id="about-menu"
                    anchorEl={anchorElAbout}
                    keepMounted
                    open={Boolean(anchorElAbout)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => { navigate('/Gallery'); handleClose(); }}>Gallery</MenuItem>
                    <MenuItem onClick={() => { navigate('/Videos'); handleClose(); }}>Videos</MenuItem>
                </Menu>

                <Button color="inherit" onClick={handleDocumentsClick}>Documents</Button>
                <Menu
                    id="documents-menu"
                    anchorEl={anchorElDocuments}
                    keepMounted
                    open={Boolean(anchorElDocuments)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => { navigate('/public-document'); handleClose(); }}>Public Documents</MenuItem>
                    <MenuItem onClick={() => { navigate('/TC'); handleClose(); }}>Transfer Certificate</MenuItem>
                    <MenuItem onClick={() => { navigate('/Announcement'); handleClose(); }}>Announcement</MenuItem>
                    <MenuItem onClick={() => { navigate('/WebsiteLinks'); handleClose(); }}>Website Links</MenuItem>
                </Menu>

                <Button color="inherit" onClick={() => navigate('/AdmissionPage')}>Admission</Button>
            </Toolbar>
        </AppBar>
    );
};

export default NavigationBar;
