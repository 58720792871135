import React from 'react';
import './SeparatorComponent.css'; // Ensure this CSS file is linked

const SeparatorComponent = () => {
    return (
        <div className="separator-component">
            <div className="separator-line"></div>
        </div>
    );
};

export default SeparatorComponent;
