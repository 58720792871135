import React from 'react';
import HeroSection from '../components/HeroSection';
import  { AboutSection, DetermineSection, WhySection, AdmissionSection, DepartmentSection, TCSection } from '../components/Section';
import SeparatorComponent from '../components/SeparatorComponent';
import  { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import notification_image from '../assets/logo/mygov.jpg'

const HomePage = () => {

   const [showModal, setShowModal] = useState(false);

   useEffect(() => {
     setShowModal(true);
   }, []);
 
   const handleClose = () => setShowModal(false);
    return (
       <>

<Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>हमारे स्कूल की वेबसाइट पर आपका स्वागत है।</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={notification_image} alt="Welcome" style={{ width: '100%', height: 'auto' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    <HeroSection />
    <SeparatorComponent/>
    <WhySection />
    <SeparatorComponent/>
    <TCSection />
    <SeparatorComponent />
    <AboutSection />
    <SeparatorComponent/>
    <AdmissionSection/>
    <SeparatorComponent />
    <DepartmentSection />
    <SeparatorComponent />
    <DetermineSection/>
    <SeparatorComponent/>
       </>
    );
};

export default HomePage;
