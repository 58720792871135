import React, { useState } from 'react';
import './ImageSearchPage.css';
import notFoundImage from '../assets/logo/not_found.png';

const ImageSearchPage = () => {
    const [year, setYear] = useState('2022'); // Default to 2022 for initial state
    const [number, setNumber] = useState('');
    const [fileSrc, setFileSrc] = useState('');
    const [error, setError] = useState('');

    const years = ['2020', '2021', '2022', '2023'];

    const handlePdfSearch = () => {
        const pdfPath = `/TC/${year}/${number}.pdf`;
        fetch(pdfPath)
            .then((res) => {
                if (res.ok && res.headers.get("Content-Type") === "application/pdf") {
                    setFileSrc(pdfPath);
                    setError('');
                } else {
                    throw new Error('PDF file not found');
                }
            })
            .catch(() => {
                setFileSrc(notFoundImage);
                setError('TC Record not found');
            });
    };

    return (
        <div className="image-search-page">
            <h1>Transfer Certificate</h1>
            <div className="search-controls">
                <input
                    type="number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="Enter number"
                />
                <select value={year} onChange={(e) => setYear(e.target.value)}>
                    {years.map(yr => (
                        <option key={yr} value={yr}>{yr}</option>
                    ))}
                </select>
                <button onClick={handlePdfSearch}>Search PDF</button>
            </div>
            <div className="image-display">
                {error && <p className='error-message'>{error}</p>}
                {fileSrc && fileSrc.endsWith('.pdf') && <embed src={`${fileSrc}#toolbar=0&navpanes=0&scrollbar=0`} type="application/pdf" width="100%" height="1080px"></embed>}
                {!fileSrc && error && <img src={notFoundImage} alt="Not found" />}
            </div>
        </div>
    );
};

export default ImageSearchPage;
