import React from 'react';

const StudentResultPage = () => {
    return (
        <div>
            <h2>Student Results</h2>
            {/* Add content from student_result.php here */}
        </div>
    );
};

export default StudentResultPage;
